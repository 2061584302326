import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'

library.add(fas, faTwitter)
// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch()
